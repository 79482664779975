import { AdvancedMarker } from '@vis.gl/react-google-maps';
import { ComponentProps } from 'react';
import MarkerUserIcon from '@assets/markers/marker-user.svg';
import styles from './index.module.scss';
import classNames from 'classnames';

interface IMarkerProps
  extends Omit<ComponentProps<typeof AdvancedMarker>, 'children'> {}

export const UserLocationMarker = ({ className, ...props }: IMarkerProps) => (
  <AdvancedMarker className={classNames(styles.marker, className)} {...props}>
    <MarkerUserIcon />
  </AdvancedMarker>
);
