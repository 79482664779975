import { useRef } from 'react';
import classNames from 'classnames';

import { Map, Marker } from '@features/shared/components/map/ui';
import { useIsVisible } from '@features/shared/hooks/useIsVisible';
import { IGeolocation } from '@models/IGeolocation';

import styles from './ContactMap.module.scss';

interface IContactMapProps {
  clubLocation: IGeolocation;
  isMobile?: boolean;
  isPanel?: boolean;
  className: string;
}

export const ContactMap = ({
  isMobile,
  clubLocation,
  isPanel,
  className,
}: IContactMapProps) => {
  const position = {
    lat: clubLocation.latitude,
    lng: clubLocation.longitude,
  };

  if (isMobile || isPanel) {
    position.lng -= 0.018;
  }

  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisible(ref);

  return (
    <div
      className={classNames(
        styles.container,
        { [styles.isPanel]: isPanel },
        className,
      )}
      ref={ref}
    >
      <Map defaultCenter={position}>
        {isVisible ? <Marker position={position} /> : null}
      </Map>
    </div>
  );
};
