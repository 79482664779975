import { APIProvider, Map as GoogleMap } from '@vis.gl/react-google-maps';
import { ComponentProps } from 'react';
import styles from './Map.module.scss';
import { MapControl } from './map-control';
import { UserLocationMarker } from './user-location-marker';

interface IMapProps extends ComponentProps<typeof GoogleMap> {
  userLocation?: { lat: number; lng: number };
  onEnableUserLocation?: () => void;
}

export const DEFAULT_ZOOM = 8;

export const DEFAULT_CENTER = {
  lat: 52.36,
  lng: 4.9,
};

const API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? '';
const MAP_ID = '414ac25a29374b51';

export const Map = ({
  userLocation,
  children,
  onEnableUserLocation,
  ...props
}: IMapProps) => {
  return (
    <APIProvider apiKey={API_KEY}>
      <GoogleMap
        mapId={MAP_ID}
        className={styles.container}
        defaultCenter={DEFAULT_CENTER}
        defaultZoom={DEFAULT_ZOOM}
        maxZoom={16}
        clickableIcons={false}
        zoomControl={false}
        fullscreenControl={false}
        streetViewControl={false}
        mapTypeControl={false}
        {...props}
      >
        <MapControl onEnableUserLocation={onEnableUserLocation} />
        {userLocation && <UserLocationMarker position={userLocation} />}
        {children}
      </GoogleMap>
    </APIProvider>
  );
};
