import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import styles from './index.module.scss';

interface Props {
  dots: {
    key: string;
    props: {
      className: string;
    };
  }[];
  currentSlide: number;
  nextSlide: number | null;
}

const MAX_DOTS = 4;

export function SlickDots({ dots, currentSlide, nextSlide }: Props) {
  const elementRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const element = elementRef?.current;
    if (!element || nextSlide === null) return;

    const dotsCount = element?.querySelectorAll('li').length || 0;

    if (dotsCount <= MAX_DOTS) return;

    const transformValue = 1.2;
    let transformCount = 0;
    if (nextSlide > currentSlide) {
      if (nextSlide > MAX_DOTS) {
        transformCount = nextSlide - MAX_DOTS;
      }
    } else {
      if (nextSlide > MAX_DOTS) {
        transformCount = MAX_DOTS - nextSlide;
      }
    }
    element.style.transform = `translateX(-${
      transformCount * transformValue
    }rem)`;
  }, [nextSlide, currentSlide]);

  return (
    <div className={styles.container}>
      <ul ref={elementRef} className={styles.dotsList}>
        {dots.map(({ key, props: dotProps }) => (
          <li
            key={key}
            className={classNames(styles.dot, styles[dotProps.className])}
          >
            <div className={styles.dotButton} />
          </li>
        ))}
      </ul>
    </div>
  );
}
