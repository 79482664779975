import ClubIdBySubscriptionType, {
  ClubDataBySubscription,
  ClubsSubscriptionData,
} from '@features/pg-funnel/config/club-id-by-subscription-type';
import theme from '@config/theme';
import { useRouter } from 'next/router';
import { ClubsById, ClubWithCmsData } from '@features/pg-funnel/utils/funnel';
import { useMemo } from 'react';

export interface ClubDataFromConfig {
  configData: ClubDataBySubscription;
  clubDataFromConfig: ClubsSubscriptionData | undefined;
  availableClubs: ClubWithCmsData[];
}

/*
  This hook looks up config data by clubId and also returns a list of availableClubs by filtering the provided clubsById based on the config data
  we pass clubsById as argument because it's used in the funnel (funnel-specific clubs) as well as the club overview page (all clubs)
 */

export const useClubDataFromConfig = (
  clubId: number | string | null | undefined,
  clubsById: ClubsById,
): ClubDataFromConfig => {
  const {
    query: { slug },
  } = useRouter();

  //  In the config we keep track of contracts & prices per club. Here we find the right config based on the environment + theme + funnel slug.
  const perfectGymUrl = process.env.NEXT_PUBLIC_PG_URL;
  const environment = perfectGymUrl?.includes('test')
    ? 'staging'
    : 'production';
  const configDataBySlug =
    ClubIdBySubscriptionType[environment]?.[theme.name]?.[slug as string];
  const defaultConfigData =
    ClubIdBySubscriptionType[environment][theme.name].default;
  // if there is no config for this specific funnel, we return the default funnel config data
  const configData = configDataBySlug || defaultConfigData;

  const clubDataFromConfig =
    configData && clubId
      ? Object?.values(configData)?.find((item) =>
          item?.clubs?.includes(Number(clubId)),
        )
      : undefined;

  // to find the clubs available to a user we compare the prices and add all clubs with the same or lower
  const availableClubIds = Object.values(configData)
    .map((tier) =>
      tier.price > Number(clubDataFromConfig?.price) ? [] : tier.clubs,
    )
    .flat();
  const availableClubs = useMemo(
    () =>
      clubsById
        ? availableClubIds.map((id) => clubsById[id]).filter(Boolean)
        : [],
    [clubsById, availableClubIds],
  );

  return { configData, clubDataFromConfig, availableClubs };
};
